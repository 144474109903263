// GRUPO ACESSO
export const enum grupoAcessoTipoEnum {
  ADMINISTRADOR = "Administrador",
  PADRAO = "Padrão",
  MINIMO = "Mínimo",
  BASICO = "Básico",
  COMPLETO = "Completo",
}

// LOJA
export const enum lojaMatrizFilialEnum {
  MATRIZ = "Matriz",
  FILIAL = "Filial",
}

export const enum lojaTipoEnum {
  NOVA = "Nova",
  CONVERSAO = "Conversão",
}

export const enum lojaStatusEnum {
  ATIVO = "Ativo",
  SUSPENSO_TEMPORARIAMENTE = "Suspenso Temporariamente",
  INATIVO = "Inativo",
}

export const enum lojaStatusInauguracaoEnum {
  INAUGURADA = "Inaugurada",
  NAO_INAUGURADA = "Não Inaugurada",
}

export const enum lojaHoraStateEnum {
  FULL_HORAS = "24 Horas",
  FECHADO = "Fechado",
  CUSTOMIZADO = "Customizado",
}

// PERFIL
export const enum perfilIdentificadorEnum {
  COLABORADOR = "colaborador",
  REDE = "rede",
  LOJA = "loja",
  SOCIO_HONORARIO = "socio-honorario",
  BALCONISTA = "balconista",
}

// REDE
export const enum redeNaturezaJuridicaEnum {
  ASSOCIACAO = "Associação",
  LICENCIAMENTO_DE_MARCA = "Licenciamento De Marca",
  FRANQUIA = "Franquia",
}

// USUARIO ACESSO
export const enum entidadeTipoEnum {
  REDE = "rede",
  LOJA = "loja",
  SOCIO_HONORARIO = "socioHonorario",
  COLABORADOR = "colaborador",
}

// # PERMISSÕES #
export const enum permissoesEnum {
  // ROTA
  VER_ROTA = "podeVerRota",

  // CARGO
  VISUALIZAR_CARGO = "podeVisualizarCargo",
  CADASTRAR_CARGO = "podeCadastrarCargo",
  EDITAR_CARGO = "podeEditarCargo",
  ATIVAR_INATIVAR_CARGO = "podeAtivarEInativarCargo",

  // DEPARTAMENTO
  VISUALIZAR_DEPARTAMENTO = "podeVisualizarDepartamento",
  CADASTRAR_DEPARTAMENTO = "podeCadastrarDepartamento",
  EDITAR_DEPARTAMENTO = "podeEditarDepartamento",
  ATIVAR_INATIVAR_DEPARTAMENTO = "podeAtivarEInativarDepartamento",

  // GRUPO DE ACESSO
  VISUALIZAR_GRUPO_ACESSO = "podeVisualizarGrupoDeAcesso",
  CADASTRAR_GRUPO_ACESSO = "podeCadastrarGrupoDeAcesso",
  EDITAR_GRUPO_ACESSO = "podeEditarGrupoDeAcesso",
  DELETAR_GRUPO_ACESSO = "podeDeletarGrupoDeAcesso",

  // LOJA
  VISUALIZAR_LOJA = "podeVisualizarLoja",
  CADASTRAR_LOJA = "podeCadastrarLoja",
  EDITAR_LOJA = "podeEditarLoja",
  EDITAR_PARCIALMENTE_LOJA = "podeEditarParcialmenteLoja",
  CADASTRAR_EDITAR_REMOVER_CONTATOS_LOJA = "podeCadastrarEditarERemoverContatosLoja",
  ATIVAR_INATIVAR_SUSPENDER_TEMP_LOJA = "podeAtivarInativarESuspenderTemporariamenteLoja",
  IMPORTAR_LOJA = "podeImportarLoja",
  DOWNLOAD_RELATORIO_LOJA = "podeDownloadRelatorioLoja",
  MAPA_LOJAS = "podeAcessarMapaLojas",

  // PRODUTO
  VISUALIZAR_PRODUTO = "podeVisualizarProduto",
  CADASTRAR_PRODUTO = "podeCadastrarProduto",
  EDITAR_PRODUTO = "podeEditarProduto",
  ATIVAR_INATIVAR_PRODUTO = "podeAtivarEInativarProduto",

  // REDE
  VISUALIZAR_REDE = "podeVisualizarRede",
  CADASTRAR_REDE = "podeCadastrarRede",
  EDITAR_REDE = "podeEditarRede",
  EDITAR_PARCIALMENTE_REDE = "podeEditarParcialmenteRede",
  CADASTRAR_EDITAR_REMOVER_CONTATOS_REDE = "podeCadastrarEditarERemoverContatosRede",
  DOWNLOAD_RELATORIO_REDE = "podeDownloadRelatorioRede",

  // SOCIO HONORÁRIO
  VISUALIZAR_SOCIO_HONORARIO = "podeVisualizarSocioHonorario",
  CADASTRAR_SOCIO_HONORARIO = "podeCadastrarSocioHonorario",
  EDITAR_SOCIO_HONORARIO = "podeEditarSocioHonorario",
  CADASTRAR_EDITAR_REMOVER_CONTATOS_SOCIO_HONORARIO = "podeCadastrarEditarERemoverContatosSocioHonorario",
  ATIVAR_INATIVAR_SOCIO_HONORARIO = "podeAtivarEInativarSocioHonorario",
  DOWNLOAD_RELATORIO_SOCIO_HONORARIO = "podeDownloadRelatorioSocioHonorario",

  // USUARIO
  VISUALIZAR_USUARIO = "podeVisualizarUsuario",
  CADASTRAR_USUARIO = "podeCadastrarUsuario",
  EDITAR_USUARIO = "podeEditarUsuario",
  REMOVER_USUARIO = "podeRemoverUsuario",
  DOWNLOAD_RELATORIO_USUARIO = "podeDownloadRelatorioUsuario",
  IMPORTAR_USUARIO = "podeImportarUsuario",
  CONVIDAR_PERMISSIONAR_USUARIO = "podeConvidarEPermissionar",
}

export const mapaPermissaoEntidade = {
  // LOJA
  [entidadeTipoEnum.LOJA]: [
    permissoesEnum.VISUALIZAR_LOJA,
    permissoesEnum.CADASTRAR_LOJA,
    permissoesEnum.EDITAR_LOJA,
    permissoesEnum.EDITAR_PARCIALMENTE_LOJA,
    permissoesEnum.CADASTRAR_EDITAR_REMOVER_CONTATOS_LOJA,
    permissoesEnum.ATIVAR_INATIVAR_SUSPENDER_TEMP_LOJA,
    permissoesEnum.IMPORTAR_LOJA,
    permissoesEnum.DOWNLOAD_RELATORIO_LOJA,
    permissoesEnum.MAPA_LOJAS,
  ],

  // REDE
  [entidadeTipoEnum.REDE]: [
    permissoesEnum.VISUALIZAR_REDE,
    permissoesEnum.CADASTRAR_REDE,
    permissoesEnum.EDITAR_REDE,
    permissoesEnum.EDITAR_PARCIALMENTE_REDE,
    permissoesEnum.CADASTRAR_EDITAR_REMOVER_CONTATOS_REDE,
    permissoesEnum.DOWNLOAD_RELATORIO_REDE,
  ],

  // SOCIO HONORÁRIO
  [entidadeTipoEnum.SOCIO_HONORARIO]: [
    permissoesEnum.VISUALIZAR_SOCIO_HONORARIO,
    permissoesEnum.CADASTRAR_SOCIO_HONORARIO,
    permissoesEnum.EDITAR_SOCIO_HONORARIO,
    permissoesEnum.CADASTRAR_EDITAR_REMOVER_CONTATOS_SOCIO_HONORARIO,
    permissoesEnum.ATIVAR_INATIVAR_SOCIO_HONORARIO,
    permissoesEnum.DOWNLOAD_RELATORIO_SOCIO_HONORARIO,
  ],
};

export const mapaPermissaoUsuario = [
  permissoesEnum.VISUALIZAR_USUARIO,
  permissoesEnum.CADASTRAR_USUARIO,
  permissoesEnum.EDITAR_USUARIO,
  permissoesEnum.REMOVER_USUARIO,
  permissoesEnum.DOWNLOAD_RELATORIO_USUARIO,
  permissoesEnum.IMPORTAR_USUARIO,
  permissoesEnum.CONVIDAR_PERMISSIONAR_USUARIO,
];

export const enum produtoEnum {
  ACODE = "acode",
  ACODE_XML = "acode-xml",
  BIT = "bit",
  BUSSOLA = "bussola",
  CADASTRO = "cadastro",
  COMPRA_CENTRALIZADA = "compra-centralizada",
  E_DELIVERY = "e-delivery",
  GERENCIAMENTO_CATEGORIAS = "gc",
  IFEPEC = "ifepec",
  MCC = "mcc",
  MINHA_SAUDE = "minha-saude",
  MOVIDESK = "movidesk",
  ORION = "orion",
  PAI = "pai",
  PBM = "pbm",
  PEC = "pec",
  PGC = "pgc",
  SIC = "sic",
  SIGPHARMA = "sigpharma",
  SOLUCOES_FEBRAFAR = "solucoes-febrafar",
  SOLUCOES_PRATICA = "solucoes-pratica",
  TRADE = "trade",
  WEB_COMPRAS = "web-compras",
}

export const mapaProdutosEntidade = {
  // LOJA
  [entidadeTipoEnum.LOJA]: [
    produtoEnum.CADASTRO,
    produtoEnum.GERENCIAMENTO_CATEGORIAS,
    produtoEnum.ORION,
    produtoEnum.PAI,
    produtoEnum.PEC,
    produtoEnum.TRADE,
    produtoEnum.WEB_COMPRAS,
  ],

  // REDE
  [entidadeTipoEnum.REDE]: [
    produtoEnum.ACODE,
    produtoEnum.ACODE_XML,
    produtoEnum.BUSSOLA,
    produtoEnum.CADASTRO,
    produtoEnum.E_DELIVERY,
    produtoEnum.GERENCIAMENTO_CATEGORIAS,
    produtoEnum.MCC,
    produtoEnum.MINHA_SAUDE,
    produtoEnum.ORION,
    produtoEnum.PAI,
    produtoEnum.PEC,
    produtoEnum.TRADE,
    produtoEnum.WEB_COMPRAS,
  ],

  // SOCIO HONORÁRIO
  [entidadeTipoEnum.SOCIO_HONORARIO]: [
    produtoEnum.CADASTRO,
    produtoEnum.GERENCIAMENTO_CATEGORIAS,
    produtoEnum.ORION,
    produtoEnum.TRADE,
  ],
};

// CLASSIFICAÇÃO DE SOCIO HONORARIO
export const enum shClassificacaoEnum {
  INDUSTRIA = "Indústria",
  DISTRIBUIDORA = "Distribuidora",
  PRESTADOR_DE_SERVICO = "Prestador de Serviço",
}

// controle das chaves de filtro
export const enum chaveFiltroEnum {
  REDE = "redeListfilter",
  LOJA = "lojaListfilter",
  SOCIO_HONORARIO = "shListfilter",
  USUARIO = "usuarioListfilter",
  GRUPO_ACESSO = "grupoAcessosListfilter",
}

export const mapaChavePerfil = [
  chaveFiltroEnum.REDE,
  chaveFiltroEnum.LOJA,
  chaveFiltroEnum.SOCIO_HONORARIO,
  chaveFiltroEnum.USUARIO,
  chaveFiltroEnum.GRUPO_ACESSO,
];

export const atualizaUsuarioAcessoTipoEnum = {
  ADICAO: "adição",
  ATUALIZACAO: "atualização",
  REMOCAO: "remoção",
};
